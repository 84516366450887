/**
 * Messages sent from the Agave Link iframe to the parent window (i.e., client's app)
 */
export enum LinkMessageType {
    Exit = 'agave.link.exit', // User clicked on X to close the iframe, so parent window should remove the iframe
    Success = 'agave.link.success', // User successfully authenticated with a source system and a public token was generated
}

export interface LinkExitMessage {
    type: LinkMessageType.Exit;
    error?: string;
}

export interface LinkSuccessMessage {
    type: LinkMessageType.Success;
    publicToken: string;
}

/*
 *
 * ---------------------------------------------------------------------------------------------------------------------
 *
 */

/**
 * Messages sent from the OAuth popup window to Agave Link iframe
 */
export enum OAuthMessageType {
    // URGENT: Keep in sync with oauth-success.blade.php
    Success = 'agave.link.popup.success', // User successfully authenticated with the source system and the popup window
    Error = 'agave.link.popup.error', // There was an error
}

export interface OAuthSuccessMessage {
    type: OAuthMessageType.Success;
    publicToken: string;
}

export interface OAuthErrorMessage {
    type: OAuthMessageType.Error;
    error: string;
}

export function isOAuthError(message: { type: unknown }): message is OAuthErrorMessage
{
    return message.type === OAuthMessageType.Error;
}

export function isOAuthSuccess(message: { type: unknown }): message is OAuthSuccessMessage
{
    return message.type === OAuthMessageType.Success;
}
