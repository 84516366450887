// noinspection DuplicatedCode,JSUnusedGlobalSymbols

import { LinkExitMessage, LinkMessageType, LinkSuccessMessage } from 'App/Link/types/Message';
import { SourceSystemOptions } from 'App/Link/types/SourceSystemOptions';
import { QueryParameters } from './Common/Components/ReactUtils';
import { CanHaveIframe, IframeContainer } from './IframeContainer';

interface OpenLinkConfig extends CanHaveIframe {
    linkToken: string;
    onSuccess?: (publicToken: string) => void;
    onExit?: (error?: string) => void;
    showSandboxSourceSystems?: boolean;
    showProductionSourceSystems?: boolean;
    sourceSystem?: string[]|string;
    sourceSystemEnvironment?: string;
    sourceSystemOptions?: SourceSystemOptions;
    category?: string;
    theme?: 'dark' | 'light';
    primaryColor?: string;
}

/**
 * This class will be used by clients and should only expose functionality they should have access to
 */
class AgaveLink extends IframeContainer<OpenLinkConfig, LinkExitMessage | LinkSuccessMessage>
{
    protected DEFAULT_IFRAME_ID = 'agave-link-iframe';

    protected readonly eventListener = (event: MessageEvent<LinkExitMessage | LinkSuccessMessage>) =>
    {
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns
        if (event.origin !== import.meta.env.VITE_APP_URL)
        {
            return;
        }

        const { type } = event.data;

        switch (type)
        {
        case LinkMessageType.Success:
            try
            {
                if (this.config.onSuccess)
                {
                    this.config.onSuccess(event.data.publicToken);
                }
            }
            finally
            {
                this.closeIframe();
            }
            break;

        case LinkMessageType.Exit:
            try
            {
                if (this.config.onExit)
                {
                    this.config.onExit(event.data.error);
                }
            }
            finally
            {
                this.closeIframe();
            }
            break;

        default:
            break;
        }
    };

    // eslint-disable-next-line require-await, @typescript-eslint/require-await
    protected async generateIframeSource()
    {
        const queryParams: Record<string, boolean | number | string> = {
            embedded: this.isEmbedded(this.config),
            linkToken: this.config.linkToken,
            sourceSystem: typeof this.config.sourceSystem === 'string'
                ? this.config.sourceSystem
                : (this.config.sourceSystem ?? []).join(','),
            sourceSystemEnvironment: this.config.sourceSystemEnvironment ?? '',
            sourceSystemOptions: JSON.stringify(this.config.sourceSystemOptions ?? {}),
            category: this.config.category ?? '',
            showSandboxSourceSystems: this.config.showSandboxSourceSystems === undefined ? false : this.config.showSandboxSourceSystems,
            showProductionSourceSystems: this.config.showProductionSourceSystems === undefined ? true : this.config.showProductionSourceSystems,
            outerHeight: window.outerHeight,
            outerWidth: window.outerWidth,
            scrollX: window.scrollX,
            scrollY: window.scrollY,
            theme: this.config.theme ?? (this.isEmbedded(this.config) ? 'light' : 'dark'),
            primaryColor: this.config.primaryColor ?? '',
        };

        return `${ import.meta.env.VITE_APP_URL }/link/embed${ QueryParameters(queryParams) }`;
    }

    /**
     * DEPRECATED: use open() instead
     */
    public openLink(config: OpenLinkConfig): void
    {
        this.open(config);
    }
}

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        AgaveLink: AgaveLink;
    }
}
window.AgaveLink = new AgaveLink();

export default AgaveLink;
