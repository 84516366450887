import { AgaveErrorData } from 'App/Common/Types/AgaveErrorData';
import axios from 'axios';
import React from 'react';

/**
 * @summary Takes a passed onClick function and returns a wrapped onClick function that
 *          ensures click event is not propogated to clicked element's parent
 * @usage <div onClick = { exclusiveClickHandler(onSelect) } />
 */
export const exclusiveClickHandler =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (onClick?: (event: React.MouseEvent<any, MouseEvent>) => void) => (event: React.MouseEvent<unknown, MouseEvent>) =>
    {
        event.stopPropagation();

        if (onClick)
        {
            onClick(event);
        }
    };

export const generateUUID = (): string =>
{
    /* eslint-disable no-magic-numbers */
    let d = new Date().getTime();
    let d2 = performance.now() * 1000;

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>
    {
        let r = Math.random() * 16;

        if (d > 0)
        {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        }
        else
        {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }

        return (c === 'x' ? r : ((r & 0x7) | 0x8)).toString(16);
    });
    /* eslint-enable no-magic-numbers */
};

export const replaceAll = (str: string, find: string, replace: string) => str.split(find).join(replace);
export const slug = (label: string) => replaceAll(label.toLowerCase(), ' ', '-');

export const Dusk = (label: string) => ({ dusk: slug(label) });

export type DivProps = React.HtmlHTMLAttributes<HTMLDivElement>;

// eslint-disable-next-line no-use-before-define
export const ObjectKeys = Object.keys as (<T extends object>(obj: T) => (keyof T)[]);

// eslint-disable-next-line no-use-before-define
export const ObjectValues = Object.values as (<T extends object>(obj: T) => T[keyof T][]);

// eslint-disable-next-line no-use-before-define
export const ObjectEntries = Object.entries as (<T extends object>(obj: T) => [keyof T, T[keyof T]][]);

// eslint-disable-next-line no-use-before-define
export const ObjectFromEntries = Object.fromEntries as (<T extends object>(entries: Iterable<readonly [keyof T, T[keyof T]]>) => T);
export const ObjectWithoutUndefinedValues = <TKey extends string, TVal>(
    obj: Partial<Record<TKey, TVal | undefined>>): Record<TKey, TVal> => ObjectFromEntries(ObjectEntries(obj)
        .filter(([ , value ]: [ TKey, TVal | undefined ]) => value !== undefined) as [TKey, TVal][]);
export const ObjectFilteredByKeys = <T extends object>(
    obj: T,
    filter: (key: keyof T) => boolean,
) => ObjectFromEntries(ObjectEntries(obj).filter(([ key ]) => filter(key)));

export const ObjectFilteredByValues = <T extends object>(
    obj: T,
    filter: (value: unknown) => boolean,
) => ObjectFromEntries(ObjectEntries(obj).filter(([
        _key,
        value,
    ]) => filter(value)));

export const ObjectFilteredByKeyValues = <T extends object>(
    obj: T,
    filter: (key: keyof T, value: unknown) => boolean,
) => ObjectFromEntries(ObjectEntries(obj).filter(([
        key,
        value,
    ]) => filter(key, value)));

export const ObjectTransformKeys = <T extends object>(
    obj: T,
    transform: (key: keyof T) => string,
) => ObjectFromEntries<Record<string, unknown>>(ObjectEntries(obj).map(([
    key,
    value,
]) => [
    transform(key),
    value,
] as const));

export const ObjectTransformValues = <T extends object, TValue>(
    obj: T,
    transform: (key: keyof T, value: T[keyof T]) => TValue,
): Record<keyof T, TValue> => ObjectFromEntries<Record<keyof T, TValue>>(ObjectEntries(obj).map(([
    key,
    value,
]) => [
    key,
    transform(key, value),
] as const));

export const ObjectTransformKeyValues = <T extends object, TValue>(
    obj: T,
    transformKey: (key: keyof T) => string,
    transformValue: (key: keyof T, value: unknown) => TValue,
) => ObjectFromEntries<Record<string, unknown>>(ObjectEntries(obj).map(([
    key,
    value,
]) => [
    transformKey(key),
    transformValue(key, value),
] as const));


export const ObjectFlip = <TKey extends string, TVal extends string>(obj: Record<TKey, TVal>): Record<TVal, TKey> => ObjectFromEntries(ObjectEntries(obj)
    .map((entry) => entry.reverse() as [TVal, TKey]));

export type QueryParams = Record<number | string, boolean | number | string | undefined>;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const MapAt = <T, >(map: Map<string, T>, key: string) => map.get(key)!;
export const MapKeys = <TKey, TVal>(map: Map<TKey, TVal>) => Array.from(map.keys());
export const MapValues = <TKey, TVal>(map: Map<TKey, TVal>) => Array.from(map.values());
export const QueryParameters = <T extends QueryParams>(obj: T) => `?${ ObjectKeys(obj)
    .filter((key) => obj[key] !== undefined)
    .map((key) => `${ encodeURIComponent(key as string) }=${ encodeURIComponent(obj[key] as string) }`)
    .join('&') }`;
export const ExtractQueryParameters = (path: string): QueryParams =>
{
    const pathParts = path.split('?');

    return pathParts.length > 1
        ? pathParts[1]
            .split('&')
            .map((paramParts) => ({ [paramParts.split('=')[0]]: paramParts.split('=')[1] }))
            .reduce((acc, curr) => ({
                ...acc,
                ...curr,
            }), {})
        : {};
};

/**
 * @summary flattens any nested object into a flat object
 * @obj Record<string, unknown> with N >= 0 depth of nested objects
 * @return Record<string, unknown> with 0 depth of nested objects
 * @example
 * input:  { parent: child: { childValue: value } }
 * output: { parent.child.childValue: value }
 */
export const flattenObject = <T extends object>(obj: T) =>
{
    const flattenedObject: Record<string, unknown> = {};

    ObjectKeys(obj).forEach((key) =>
    {
        const value = obj[key] as unknown;

        if (typeof value === 'object' && value !== null)
        {
            const childObject = flattenObject(value as unknown as object);

            ObjectKeys(childObject).forEach((childKey) =>
            {
                flattenedObject[`${ String(key) }.${ String(childKey) }`] = childObject[childKey];
            });
        }
        else
        {
            flattenedObject[String(key)] = value;
        }
    });

    return flattenedObject;
};

/**
 * @summary flattens any nested object into a flat object and converts any array values to strings
 * @obj Record<string, unknown>
 * @return Record<string, string>
 * @example
 * input:  { parent: child: { childValue: [ value1, value2 ] } }
 * output: { parent.child.childValue: value1,value2 }
 */
// eslint-disable-next-line no-use-before-define
export const toRenderableObject = <T extends object>(obj?: T) =>
{
    if (!obj)
    {
        return {};
    }

    const flattenedObject = flattenObject(obj);
    const renderableObject: Record<string, unknown> = {};

    ObjectKeys(flattenedObject).forEach((key) =>
    {
        const value = flattenedObject[key];

        if (Array.isArray(value))
        {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            renderableObject[`${ key }`] = value.length > 0
                ? value[0] instanceof Object
                    ? '[Object]'
                    : value.join(',')
                : null;
        }
        else
        {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            renderableObject[`${ key }`] = value === ''
                ? null
                : value === true || value === false ? `${ value ? 'true' : 'false' }` : value;
        }
    });

    return renderableObject;
};

export const titleCase = (s: string) => s.replace(/^[-_]*(.)/, (_, char: string) => char.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, char: string) => ` ${ char.toUpperCase() }`); // First char after each -/_

export const unique = <T, >(arr: T[], hasher: (obj: T) => unknown = (obj) => obj) => (
    MapValues(new Map(arr.map((obj) => [
        hasher(obj),
        obj,
    ])))
);

export const filterOutUndefined = <T, >(arr: (T | undefined)[]) => arr.filter((item) => item !== undefined) as T[];

export const isEmpty = (value?: unknown | null | undefined) => value === null || value === undefined || value === '';

export const floatStrPrecision = (precision: number, str?: string) => (str ? Number.parseFloat(str).toFixed(precision) : undefined);

// eslint-disable-next-line array-element-newline
export const partition = <T, >(array: T[], filter: (item: T) => boolean) => array.reduce(([ pass, fail ], item) => (filter(item)
    ? [
        [
            ...pass,
            item,
        ],
        fail,
    ]
    : [
        pass,
        [
            ...fail,
            item,
        ],
    ]), [
    [] as T[],
    [] as T[],
]);

export const chunk = <T, >(array: T[], chunkSize: number) =>
{
    if (chunkSize === 0)
    {
        throw new Error('chunk size must be greater than 0');
    }

    const chunks: T[][] = [];

    for (let i = 0; i < array.length; i += chunkSize)
    {
        chunks.push(array.slice(i, i + chunkSize));
    }

    return chunks;
};

/** ADD YOUR OWN SELECTION OF PRIMITIVES **/
// eslint-disable-next-line @typescript-eslint/ban-types
type Primitives = Buffer | Date | Function | RegExp | bigint | boolean | number | string | symbol | null | undefined | void;

export type RecursivePartial<T> = T extends Primitives
  ? T /** RESOLVE PRIMITIVE TO ITSELF */
  : T extends (infer U)[]
  ? RecursivePartial<U>[] /** RESOLVE ARRAY */
  : T extends Map<infer K, infer V>
  ? Map<RecursivePartial<K>, RecursivePartial<V>> /** RESOLVE MAP */
  : T extends WeakMap<infer K, infer V>
  ? WeakMap<RecursivePartial<K>, RecursivePartial<V>> /** RESOLVE WEAK-MAP */
  : T extends Set<infer V>
  ? Set<RecursivePartial<V>> /** RESOLVE SET */
  : T extends WeakSet<infer V>
  ? WeakSet<RecursivePartial<V>> /** RESOLVE WEAK-SET */
  : T extends object
  ? {
      [K in keyof T]?: RecursivePartial<T[K]> /** RESOLVES OBJECT */;
    }
  : T; /** FALLBACK TO ITSELF IF NOT HANDLED */

// eslint-disable-next-line require-await
export async function sleep(ms: number)
{
    return new Promise((resolve) => setTimeout(resolve, ms));
}

// eslint-disable-next-line no-magic-numbers
export const randomString = () => (Math.random() + 1).toString(36).substring(2);

export const assertNever = (x: never): never =>
{
    throw new Error(`Unexpected object: ${ String(x) }`);
};

export const areSetsEqual = <T, >(a: Set<T>, b: Set<T>): boolean => (
    a.size === b.size && [ ...a ].every((value) => b.has(value))
);

export const iEquals = (a?: string, b?: string): boolean => (
    (a ?? '').localeCompare(b ?? '', undefined, { sensitivity: 'accent' }) === 0
);

export const isNumeric = (str: string) => Number.isFinite(Number.parseFloat(str)) && Number.isFinite(Number(str));

const translateError = (error?: string) =>
{
    // This seems to just be a generic error Axios throws when it has internet connectivity issues
    if (error?.includes('Network Error') === true)
    {
        return error.replace(
            'Network Error',
            'Network Error: We had an issue connecting to the source system. Please try again.'
        );
    }

    return error;
};

const extractErrorFromExceptionImpl = (error: unknown): string|undefined =>
{
    if (error !== undefined && axios.isAxiosError(error) && error.response && error.response.data !== undefined)
    {
        const data = error.response.data as AgaveErrorData;

        if (data.errors && Object.keys(data.errors).length > 0)
        {
            return Object.values(data.errors).flat()
                .join(' - ');
        }

        if (data.error && data.error !== '')
        {
            return data.error;
        }

        if (data.message && data.message !== '')
        {
            return data.message;
        }

        return JSON.stringify(data, null, 2);
    }
    else if (error instanceof Error)
    {
        return error.message;
    }
    else if (typeof error === 'string')
    {
        return error;
    }

    console.error('Unknown error', error);

    return undefined;
};

// https://stackoverflow.com/a/63045455
export type NonUndefined<T> = T extends null | undefined ? never : T;

export const extractErrorFromException = <T, >(error: NonUndefined<T>) => translateError(extractErrorFromExceptionImpl(error));

export function isAffirmative(object: unknown)
{
    if (object === true)
    {
        return true;
    }

    if (typeof object === 'string')
    {
        const cleanedString = object.toLowerCase().trim();

        return (
            cleanedString === 'true' ||
            cleanedString === 'yes' ||
            cleanedString === 'y' ||
            cleanedString === '1'
        );
    }

    return false;
}
